import { usePage } from "@inertiajs/vue3";
import { App } from "vue";

type Locale = {
    catalog: {
        [key: string]: string | string[];
    };
    format: {
        DATETIME_FORMAT: string;
        DATETIME_INPUT_FORMATS: string[];
        DATE_FORMAT: string;
        DATE_INPUT_FORMATS: string[];
        DECIMAL_SEPARATOR: string;
        FIRST_DAY_OF_WEEK: number;
        MONTH_DAY_FORMAT: string;
        NUMBER_GROUPING: number;
        SHORT_DATETIME_FORMAT: string;
        SHORT_DATE_FORMAT: string;
        THOUSAND_SEPARATOR: string;
        TIME_FORMAT: string;
        TIME_INPUT_FORMATS: string[];
        YEAR_MONTH_FORMAT: string;
    };
};

const locale = import.meta.glob("../locale/**/*.json", {
    eager: true,
    import: "default",
});

const getCatalog = () => {
    const lang = usePage().props?.language || "en";
    return (locale[`../locale/${lang}/djangojs.json`] as Locale).catalog;
};

const pluralidx = (n: number) => {
    const v = n != 1;
    return v ? 1 : 0;
};

const $gettext = (msgid: string): string => {
    const catalog = getCatalog();

    const value = catalog[msgid];
    if (typeof value === "undefined") {
        return msgid;
    } else {
        return typeof value === "string" ? value : value[0];
    }
};

const $ngettext = (singular: string, plural: string, count: number): string => {
    const catalog = getCatalog();

    const value = catalog[singular];
    if (typeof value === "undefined") {
        return count == 1 ? singular : plural;
    } else {
        return Array.isArray(value) ? value[pluralidx(count)] : value;
    }
};

const $pgettext = (context: string, msgid: string): string => {
    let value = $gettext(context + "\x04" + msgid);
    if (value.includes("\x04")) {
        value = msgid;
    }
    return value;
};

const $npgettext = (
    context: string,
    singular: string,
    plural: string,
    count: number,
): string => {
    let value = $ngettext(
        context + "\x04" + singular,
        context + "\x04" + plural,
        count,
    );
    if (value.includes("\x04")) {
        value = $ngettext(singular, plural, count);
    }
    return value;
};

const $interpolate = (
    fmt: string,
    obj: unknown[] | { [key: string]: unknown },
    named?: boolean,
): string => {
    if (named && !Array.isArray(obj)) {
        return fmt.replace(/%\(\w+\)s/g, (match) =>
            String(obj[match.slice(2, -2)]),
        );
    } else {
        return Array.isArray(obj)
            ? fmt.replace(/%s/g, () => String(obj.shift()))
            : fmt.replace(/%s/g, "");
    }
};

const translations = {
    install: (app: App) => {
        app.config.globalProperties.$gettext = $gettext;
        app.config.globalProperties.$ngettext = $ngettext;
        app.config.globalProperties.$pgettext = $pgettext;
        app.config.globalProperties.$npgettext = $npgettext;
        app.config.globalProperties.$interpolate = $interpolate;
    },
};

export { $gettext, $ngettext, $pgettext, $npgettext, $interpolate };
export default translations;
