import "vite/modulepreload-polyfill";
import "./main.css";

import * as Sentry from "@sentry/vue";
// todo deprecated
import { BrowserTracing } from "@sentry/tracing";

import { createApp, DefineComponent, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import axios from "axios";

import routeReverse from "@/plugins/route-reverse";
import translations from "@/plugins/translations";

const pages = import.meta.glob("./pages/**/*.vue");

import Vue3Lottie from "vue3-lottie";
import { MotionPlugin } from "@vueuse/motion";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

// todo update main.ts
createInertiaApp({
    resolve: async (name) => {
        return (await pages[`./pages/${name}.vue`]()) as DefineComponent;
    },
    setup({ el, App, props, plugin }) {
        const vueApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(routeReverse)
            .use(translations)
            .use(MotionPlugin)
            .use(Vue3Lottie);

        if (props.initialPage.props.envSentryDsn) {
            Sentry.init({
                app: vueApp,
                dsn: props.initialPage.props.envSentryDsn as string,
                integrations: [new BrowserTracing()],
            });
        }
        vueApp.mount(el);
    },
    progress: {
        color: "#5fcced",
    },
});
